// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import "../css/app.scss"

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import deps with the dep name or local files with a relative path, for example:
//
//     import {Socket} from "phoenix"
//     import socket from "./socket"
//
import "phoenix_html"
import {Socket} from "phoenix"
import NProgress from "nprogress"
import {LiveSocket} from "phoenix_live_view"
import 'alpinejs'
// import { PhoenixLiveViewDropzone } from "phoenix_live_view_drop_zone";

let Hooks = {};
// Hooks.PhoenixLiveViewDropzone = new PhoenixLiveViewDropzone();

Hooks.GeoLocationHook = {
  mounted() {
  	console.log("mounted")

  	let main_this = this; 

	if (navigator.geolocation) {
	    navigator.geolocation.getCurrentPosition(showPosition, showError);
	} else { 
	    console.log("Geolocation is not supported by this browser.");
	}

	function showPosition(position) {
	  main_this.pushEvent("location_status_update", {latitude: position.coords.latitude, longitude: position.coords.longitude})
	}

	function showError(error) {
	  switch(error.code) {
	    case error.PERMISSION_DENIED:
	      main_this.pushEvent("location_error", {error: "User denied the request for Geolocation."})
	      break;
	    case error.POSITION_UNAVAILABLE:
	      main_this.pushEvent("location_error", {error: "Location information is unavailable."})
	      break;
	    case error.TIMEOUT:
	      main_this.pushEvent("location_error", {error: "The request to get user location timed out."})
	      break;
	    case error.UNKNOWN_ERROR:
	      main_this.pushEvent("location_error", {error: "An unknown error occurred."})
	      break;
	  }
	}

	console.log("done")
  },
  updated(){
  	console.log("updated")
  }
}


let uploaders = {}

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;

}

uploaders.DOSI = function(entries, onViewError){
  entries.forEach(entry => {
    // create the upload session with UpChunk
    let { file, meta: { url } } = entry
    if (file.type.indexOf("image") >= 0) {
      var reader = new FileReader();
      reader.onload = function (readerEvent) {
        // console.log("Read file")
        var image = new Image();
        image.onload = function (imageEvent) {
          // Resize the image
          var canvas = document.createElement('canvas'),
            max_width = 1600,// TODO : pull max size from a site config
            max_height = 1200,// TODO : pull max size from a site config
            width = image.width,
            height = image.height;
          if (width > max_width) {
            height *= max_width / width;
            width = max_width;
          }
          if (height > max_height) {
            width *= max_height / height;
            height = max_height;
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          var dataUrl = canvas.toDataURL(file.type);
          // console.log(dataUrl)
          let blob = dataURItoBlob(dataUrl);
          var xhr = new XMLHttpRequest()

          xhr.upload.addEventListener("loadstart", function () {
            // console.log("Load started")
          });

          xhr.upload.addEventListener("progress", function (e) {
            // console.log("******************")
            // console.log(e)
            if(e.lengthComputable){
              let percent = Math.round((e.loaded / e.total) * 100)
              entry.progress(percent)
            }
          })

          xhr.addEventListener('readystatechange', function (e) {
            if (xhr.readyState == 4 && xhr.status == 200) {
              // console.log("Done")
            }
            else if (xhr.readyState == 4 && xhr.status != 200) {
              // console.log("Error")
              entry.error("Upload Failed")
            }
          })
          xhr.open('PUT', url, true)
          xhr.setRequestHeader("x-amz-acl", "public-read")
          xhr.setRequestHeader("Content-Type", blob.type)
          xhr.send(blob)
        }
        image.src = readerEvent.target.result;
      }
      // console.log("Reading File")
      reader.readAsDataURL(file);
    } else {
      var xhr = new XMLHttpRequest()

      xhr.upload.addEventListener("loadstart", function () {
         console.log("Load started")
      });

      xhr.upload.addEventListener("progress", function (e) {
        // console.log("******************")
        // console.log(e)
        if(e.lengthComputable){
          let percent = Math.round((e.loaded / e.total) * 100)
          entry.progress(percent)
        }
      })

      xhr.addEventListener('readystatechange', function (e) {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // console.log("Done")
        }
        else if (xhr.readyState == 4 && xhr.status != 200) {
          // console.log("Error")
          entry.error("Upload Failed")
        }
      })
      xhr.open('PUT', url, true)
      xhr.setRequestHeader("x-amz-acl", "public-read")
      xhr.setRequestHeader("Content-Type", file.type)
      xhr.send(file)
    }
  })
}

let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {hooks: Hooks, dom: {
    onBeforeElUpdated(from, to) {
      if (from.__x) { window.Alpine.clone(from.__x, to) }
    }
  },uploaders: uploaders,params: {_csrf_token: csrfToken}})

// Show progress bar on live navigation and form submits
window.addEventListener("phx:page-loading-start", info => NProgress.start())
window.addEventListener("phx:page-loading-stop", info => NProgress.done())

// connect if there are any LiveViews on the page
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)
window.liveSocket = liveSocket
